/* eslint-disable ember/no-jquery, ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { resolve } from 'rsvp';
import Route from '@ember/routing/route';
import config from '../config/environment';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import HoldingsToolRoute from "../mixins/holdings-tool-route";
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';
import getRedirectURLIfProxying from 'libkey-web/utils/get-redirect-url-if-proxying';
import window from 'ember-window-mock';

export default class ApplicationRoute extends Route.extend(RouteAuthHelpers, HoldingsToolRoute) {
  @service flashMessages;
  @service errorReporter;
  @service applicationSession;
  @service auth;
  @service intl;
  @service media;
  @service analytics;
  @service router;
  @service store;

  async fireOpenedEvent(libraryId, routeName) {
    const analytics = this.analytics;

    if ( libraryId && libraryId !== 'unaffiliated') {
      analytics.setCustomDimension(1, libraryId);
    }

    await analytics.recordEvent({
      category: 'Activity',
      action: 'Opened',
      routeName: routeName
    });
  }

  async beforeModel(transition) {
    let { to: { queryParams, params, name: targetName } } = transition;
    await this.setHoldingsToolLibraryGroup();
    if ((this.media.isMobile || this.media.isTabletportrait)) {
      $('body').css('overflow-x', 'hidden');
    }

    const applicationSession = this.applicationSession;
    const analytics = this.analytics;

    // Detect if this is the user first arriving at libkey
    const firstTransition = !applicationSession._anyTransitionRun;
    applicationSession.set('_anyTransitionRun', true);

    if (firstTransition) {
      analytics.setUTMDimension('utm_source', queryParams.utm_source);
      analytics.setUTMDimension('utm_medium', queryParams.utm_medium);
      analytics.setUTMDimension('utm_campaign', queryParams.utm_campaign);
      analytics.setUTMDimension('utm_term', queryParams.utm_term);
      analytics.setUTMDimension('utm_content', queryParams.utm_content);
    }

    const selectedLibrary = applicationSession.selectedLibrary;
    applicationSession.set('originalSelectedLibrary', selectedLibrary);

    let findLibraryRecord = (!!selectedLibrary && selectedLibrary !== 'unaffiliated')
      ? this.store.findRecord('library', selectedLibrary) : resolve();

    // If we haven't figured out the selected library yet,
    // try to figure it out from the route parameters
    if (!selectedLibrary) {
      const libraryParams = Object.values(params).find(({library_id}) => library_id) || {};
      const libraryId = queryParams.selectedLibrary || libraryParams.library_id

      if (libraryId) {
        applicationSession.set('selectedLibrary', libraryId);
        findLibraryRecord = this.store.findRecord('library', libraryId);
        await this.fireOpenedEvent(libraryId, targetName);
      } else {
        await this.fireOpenedEvent(null, targetName);
        // When there's no selected library the locale should be us english
        this.intl.setLocale(['en-us']);

        if(config.environment === 'test') {
          return;
        }

        console.warn('Could not associate window with a selected library from queryParameters or route parameters, leaving window unassociated');

        // Try to associate the window with a session
        // By inspecting token expiration times or some other strategy
        // TODO: maybe?
        return;
      }
    } else {
      await this.fireOpenedEvent(selectedLibrary, targetName);
    }

    return findLibraryRecord.then((library) => {
      if (!library) {
        return;
      }

      const redirectURL = getRedirectURLIfProxying({
        windowLocationURL: window.location,
        baseURLForProxyComparison: config.baseURLForProxyComparison,
        holdingsToolSubdomains: config.holdingsToolSubdomains
      });
      if (redirectURL) {
        window.location = redirectURL;
      }

        if (!this._sessionEventsRegistered) {
          this._sessionEventsRegistered = true;
        } else {
          super.beforeModel(transition);
        }
      })
      .catch(() => {
        //error contacting the server? just forward it through to the app
        // however, revert any library selection performed
        const originalSelectedLibrary = applicationSession.get(
          "originalSelectedLibrary"
        );
        applicationSession.set("selectedLibrary", originalSelectedLibrary);

        if (!this._sessionEventsRegistered) {
          this._sessionEventsRegistered = true;
        } else {
          super.beforeModel(transition);
        }
      });
  }

  @action
  loading(transition) {
    $("body").css("background-color","#2c2c4e");

    transition.promise.finally(() => {
      //Ember.$("body").css("background-color","");
    });

    return true;
  }

  @action
  error(error, transition) {

    const sessionHasExpired = error instanceof ApiSessionExpiredError;
    if (sessionHasExpired || error instanceof AuthRequiredError) {
      const selectedLibrary = this.applicationSession.selectedLibrary;
      if (selectedLibrary) {
        return this.handleAPIAuthDemandError(error, selectedLibrary, transition);
      } else {

        // Somehow a route slipped out of development that makes API calls
        // that require auth, but can have no library selected.  This
        // shouldn't happen, we should be handling this case in the specific route
        // If somehow an error bubbled up here and no library is selected,
        // then let the code fall through to the code below where its logged
        // and then user is sent to the error route

        console.warn('Received unexpected Auth demand: API demands auth, but no library is selected');
      }
    }

    //transition.intent.url seems to be the only way to find the previously-visited URL,
    //  and it seems to not be in the public API, so want to access it and protect against
    //  any future changes that may make this undefined
    if (transition && transition.intent && transition.intent.url) {
      error.lastTransitionUrl = transition.intent.url;
    }

    try {
      this.errorReporter.reportUnknownError("error transitioning to " + transition.to.name, error);
    } catch(err) {
      console.error(err); //unlikely event, an error while reporting the error
    }

    // Clear style from loading substate if its still set before going to error route
    $('body').css('background-color','');
    return true; //continue bubbling to error route
  }

  @action
  goToCurrentLibrary() {
    let libraryId = this.applicationSession.selectedLibrary;

    if (libraryId) {
      this.router.transitionTo('library', libraryId);
    } else {
      this.router.transitionTo('libraries');
    }
  }

  title(tokens) {
    return `${tokens.reverse().join(' — ')}${tokens.length > 0 ? ' — ' : ''}LibKey`;
  }

}
