define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de-de", {
    "affiliated_cant_find_article_for_doi_message": "Diese DOI wird nicht erkannt, entweder weil er falsch ist oder weil er nicht Teil des LibKey-Systems ist.",
    "affiliated_cant_find_article_for_pmid_message": "This PMID is not recognized. It could not be found in PubMed.",
    "article_not_available_message": "In Ihrer Bibliothek ist dieser Artikel nicht verfügbar",
    "article_not_found_error_message": "LibKey kann den von Ihnen angeforderten Artikel nicht finden. Bitte überprüfen Sie Ihren Link und versuchen Sie es erneut",
    "articles_in_press": "Vorveröffentlichung",
    "authentication_failed_message": "Authentifizierung fehlgeschlagen",
    "cabells_domain_details_summary_header_text": "Summary of Details from Cabells",
    "cabells_domain_link_header_text": "Detailed Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/99999999999-problematic-domains\">Learn More</a>):",
    "cabells_domain_link_text": "View Cabells Report",
    "cancel": "Abbrechen",
    "cant_find_article_have_unpaywall_message": "Diese DOI wird nicht erkannt, entweder weil er falsch ist oder weil er nicht Teil des LibKey-Systems ist.",
    "cant_locate_full_text_message": "Wir konnten den Volltextdateizugriff für Ihre Bibliothek nicht finden",
    "cant_recognize_id_type_message": "Wir konnten die eingegebene ID nicht als DOI oder PMID erkennen. Möglicherweise liegt ein Tippfehler in der eingegebenen ID vor.",
    "choose_a_language": "Wählen Sie eine Sprache",
    "controls": {
      "access_options_link_text": "Alle Optionen für den Zugriff auf Inhalte für diesen Artikel anzeigen",
      "article_in_context_link_text": "Artikel im kontext anzeigen",
      "article_link_link_text": "Artikel-Link",
      "assistance_link_text": "LINK ZUR HOMEPAGE DER BIBLIOTHEK",
      "change_library_link_text": "Nicht Ihre Organisation?",
      "default_fallback_template_label": "Optionen für den Bibliothekszugriff",
      "default_fallback_url_label": "Webseite der Bibliothek",
      "download_pdf_link_text": "PDF Herunterladen",
      "find_another_article_link_text": "Einen anderen Artikel suchen",
      "ill_button_text": "DIESEN ARTIKEL ANFORDERN UND/ODER WEITERE QUELLEN PRÜFEN",
      "link_resolver_link_text": "Optionen für den Bibliothekszugriff",
      "remember_format_choice_aria_label": "Drücken Sie die Leertaste, um sich die Formatauswahl 24 Stunden lang automatisch zu merken",
      "remember_format_choice_label_text": "Automatisches Speichern der Formatauswahl für 24 Stunden",
      "request_another_article_link_text": "Einen weiteren Artikel anfordern",
      "search_button_default_text": "Artikel suchen",
      "search_button_doi_text": "DOI suchen",
      "search_button_error_text": "Ungültige Eingabe",
      "search_button_pmid_text": "PMID suchen",
      "select_library_button_text": "Bestätigen",
      "select_library_placeholder": "Erweitern, um den Bibliotheksnamen zu durchsuchen oder nach diesem zu suchen",
      "select_unaffiliated_link_text": "Organisation nicht gelistet/Unaffiliert",
      "submit_fulfillment_request_button_default_text": "Anfordern",
      "submit_fulfillment_request_button_error_text": "Ungültige E-Mail-Adresse",
      "unpaywall_article_manuscript_pdf_link_text": "Manuskript-PDF (Open Access über Unpaywall)",
      "unpaywall_article_manuscript_web_link_text": "Manuskript-Weblink (Open Access über Unpaywall)",
      "unpaywall_article_pdf_link_text": "Artikel-PDF (Open Access über Unpaywall)",
      "unpaywall_article_web_link_text": "Artikel-Weblink (Open Access über Unpaywall)"
    },
    "default_error_screen_message_1": "Bei der Kontaktaufnahme mit der LibKey-Datenbank ist ein Fehler aufgetreten.",
    "default_error_screen_message_2": "Third Iron Support wurde informiert.",
    "discover_more_header": "Erfahren Sie mehr:",
    "email_article_request": "Artikelanfrage per E-Mail",
    "expression_of_concern_details_header": "Details zur Bedenkenbekundungsinformationen (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000607312-about-expressions-of-concern\">mehr erfahren</a>):",
    "expression_of_concern_notice_link_text": "BEDENKENBEKUNDUNGSHINWEIS",
    "fields": {
      "email_input_aria_label": "Your @nhs.uk Email Address",
      "email_input_placeholder": "jane.smith@nhs.uk",
      "email_input_title": "Your @nhs.uk Email Address",
      "search_input_aria_label": "Suchen Sie einen Artikel nach DOI oder PMID",
      "search_input_placeholder": "Suchen Sie einen Artikel nach DOI oder PMID",
      "search_input_title": "Suchen Sie einen Artikel nach DOI oder PMID"
    },
    "format_chooser_region_aria_label": "Article Information region",
    "found_article_heading": "Gefunden!",
    "full_text_format_options_header": "Optionen für das Volltextformat:",
    "generic_journal_cover_alt_text": "Generisches Zeitschriftendeckblatt",
    "ill_teaser_message": "... Aber vielleicht können wir es für Sie besorgen!",
    "ip_out_of_range_error": "Ihre IP-Adresse ist <code>{ipAddress}</code> und liegt nicht innerhalb des autorisierten IP-Bereichs für die Institution \"{libraryName}\".",
    "journal_cover_alt_text": "Titelseite von {journalTitle}",
    "libkey": {
      "description": "LibKey.io ist eine <a href=\"https://thirdiron.com/\" class=\"orange-emphasis\" target=\"_blank\">Third Iron</a> Technologie, die von Ihrer Bibliothek unterstützt wird",
      "powered_by": "Powered by",
      "tagline": "Sofortiger Zugriff auf Millionen von Artikeln, die von Ihrer Bibliothek bereitgestellt werden"
    },
    "libkey_logo_alt_text": "LibKey-Logo",
    "libkey_logo_with_question_mark_alt_text": "LibKey-Logo mit Fragezeichen oben",
    "library_attribution_aria_label": "Zugang bereitgestellt von {libraryName}",
    "library_attribution_header": "Zugang bereitgestellt von",
    "library_attribution_header_all_caps": "ZUGANG BEREITGESTELLT VON",
    "library_logo_alt_text": "Logo for {libraryName}",
    "linking_options_header": "Verlinkungsmöglichkeiten:",
    "locating_article_message": "Artikel suchen...",
    "more_authentication_info": "Weitere Authentifizierungsinformationen",
    "no_full_text_message": "Ihre Bibliothek hat nicht den Volltext von:",
    "ok": "OK",
    "permissions_error_message": "Sie sind nicht berechtigt, diese Funktion zu verwenden. Wenn dies unerwartet ist, wenden Sie sich bitte an <a href=\"mailto:support@thirdiron.com\">support@thirdiron.com,</a> um Unterstützung zu erhalten.",
    "problematic_domain_journals_header_text": "This domain is associated with {x} problematic journals including:",
    "problematic_domain_violations_header_text": "Journals associated with this domain have violations including:",
    "problematic_domain_watermark_alt": "Problematic Domain",
    "problematic_journal_cabells_link_text": "View Cabells Report",
    "problematic_journal_cabells_report_header": "Journal Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/99999999999-problematic-journals\">Learn More</a>):",
    "problematic_journal_violation_details_header": "Violation Details from Cabells",
    "problematic_journal_watermark_alt": "Problematic Journal",
    "retraction_details_header": "Details zum Widerruf (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000569841\">mehr erfahren</a>):",
    "retraction_image_alt": "Zurückgezogen",
    "retraction_multiple_related_article_link_text": "Retraction Watch verwandter Artikel {linkNumber}",
    "retraction_notice_link_text": "OFFIZIELLER WIDERRUF",
    "retraction_reasons_header": "Grund für den Widerruf von Retraction Watch:",
    "retraction_single_related_article_link_text": "Retraction Watch verwandter Artikel",
    "retrieval_assistance_message": "Bitte wenden Sie sich an Ihre Bibliothek, um Hilfe beim Abrufen dieses Artikels zu erhalten.",
    "select_library_prompt": "Wählen Sie Ihre Organisation aus, um zustarten:",
    "splash_panel_help_link_aria_label": "Hilfe-Link",
    "splash_panel_help_link_title": "LibKey-Support und FAQ",
    "try_again_button": "Wiederholen",
    "unaffiliated_icon_alt_text": "Unaffiliertes Symbol",
    "unpaywall_notice": "Anmerkung! Artikellinks von Unpaywall werden automatisch gesammelt und können sich im Laufe der Zeit ändern. Wenn die obigen Links nicht richtig funktionieren, klicken Sie auf den untenstehenden Link, um die Optionen zu erhalten, die in Ihrer Bibliothek verfügbar sind, um den Artikel zu erhalten.\n",
    "visit_third_iron_support_message": "Besuchen Sie den Third Iron Support",
    "vpn_error_message": "Fehler beim Herstellen einer Verbindung mit dem Konto {libraryName} ({libraryId}) von {ipAddress}",
    "vpn_link_message": "Wenn Sie Hilfe bei der Einrichtung Ihres VPNs benötigen, lesen Sie bitte <a href=\"{url}\" target=\"_blank\">die VPN-Anmeldeinformationen Ihrer Institution.</a>",
    "wayfless_lookup_no_id_error_message_1": "Für die Suche wurde keine DOI oder PMID angegeben.",
    "wayfless_lookup_no_id_error_message_2": "Bitte versuchen Sie es erneut.",
    "your_ip_address": "Ihre IP-Adresse:"
  }], ["en-us", {
    "affiliated_cant_find_article_for_doi_message": "This DOI is not recognized, either because it is incorrect or it is not part of the LibKey system.",
    "affiliated_cant_find_article_for_pmid_message": "This PMID is not recognized. It could not be found in PubMed.",
    "article_not_available_message": "Your library does not have this article available",
    "article_not_found_error_message": "LibKey cannot find the article that you requested.  Please check your link and try again",
    "articles_in_press": "Articles in Press",
    "authentication_failed_message": "Authentication Failed",
    "cabells_domain_details_summary_header_text": "Summary of Details from Cabells",
    "cabells_domain_link_header_text": "Detailed Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000637762\">Learn More</a>):",
    "cabells_domain_link_text": "View Cabells Report",
    "cancel": "Cancel",
    "cant_find_article_have_unpaywall_message": "This DOI is not recognized, either because it is incorrect or it is not part of the LibKey system.",
    "cant_locate_full_text_message": "We were unable to locate full text file access for your library",
    "cant_recognize_id_type_message": "We were unable to recognize the entered id as a DOI or PMID. There may be a typo in the entered id.",
    "choose_a_language": "Choose a language",
    "controls": {
      "access_options_link_text": "See all content access options for this article",
      "article_in_context_link_text": "View article in context",
      "article_link_link_text": "Article Link",
      "assistance_link_text": "LINK TO LIBRARY HOME PAGE",
      "change_library_link_text": "Not your organization?",
      "default_fallback_template_label": "Library Access Options",
      "default_fallback_url_label": "Library Website",
      "download_pdf_link_text": "Download PDF",
      "find_another_article_link_text": "Find another article",
      "ill_button_text": "REQUEST THIS ITEM AND/OR CHECK ADDITIONAL SOURCES",
      "link_resolver_link_text": "Library Access Options",
      "remember_format_choice_aria_label": "Press the space bar to automatically remember format choice for 24 hours",
      "remember_format_choice_label_text": "Automatically remember format choice for 24 hours",
      "request_another_article_link_text": "Request another article",
      "search_button_default_text": "Find Article",
      "search_button_doi_text": "Find DOI",
      "search_button_error_text": "Invalid Input",
      "search_button_pmid_text": "Find PMID",
      "select_library_button_text": "Confirm",
      "select_library_placeholder": "Expand to browse or search by library name",
      "select_unaffiliated_link_text": "Organization not listed/Unaffiliated",
      "submit_fulfillment_request_button_default_text": "Request",
      "submit_fulfillment_request_button_error_text": "Invalid Email",
      "unpaywall_article_manuscript_pdf_link_text": "Manuscript PDF (Open Access via Unpaywall)",
      "unpaywall_article_manuscript_web_link_text": "Manuscript Web Link (Open Access via Unpaywall)",
      "unpaywall_article_pdf_link_text": "Article PDF (Open Access via Unpaywall)",
      "unpaywall_article_web_link_text": "Article Web Link (Open Access via Unpaywall)"
    },
    "default_error_screen_message_1": "An error occurred while contacting the LibKey database.",
    "default_error_screen_message_2": "Third Iron support has been notified.",
    "discover_more_header": "Discover More:",
    "email_article_request": "Email Article Request",
    "expression_of_concern_details_header": "Expression of Concern Details (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000607312-about-expressions-of-concern\">Learn More</a>):",
    "expression_of_concern_notice_link_text": "EXPRESSION OF CONCERN NOTICE",
    "fields": {
      "email_input_aria_label": "Your @nhs.uk Email Address",
      "email_input_placeholder": "jane.smith@nhs.uk",
      "email_input_title": "Your @nhs.uk Email Address",
      "search_input_aria_label": "Find an article by DOI or PMID",
      "search_input_placeholder": "Find an article by DOI or PMID",
      "search_input_title": "Find an article by DOI or PMID"
    },
    "format_chooser_region_aria_label": "Article Information region",
    "found_article_heading": "Found It!",
    "full_text_format_options_header": "Full Text Format Options:",
    "generic_journal_cover_alt_text": "Generic journal cover image",
    "ill_teaser_message": "...but we may be able to get it for you!",
    "ip_out_of_range_error": "Authentication failed. Your IP address is <code>{ipAddress}</code> which is not within the authorized IP range for the institution \"{libraryName}\".",
    "journal_cover_alt_text": "Cover of {journalTitle}",
    "libkey": {
      "description": "LibKey.io is a <a href=\"https://thirdiron.com/\" class=\"orange-emphasis\" target=\"_blank\">Third Iron</a> technology supported by your library",
      "powered_by": "Powered by",
      "tagline": "Instant access to millions of articles provided by your library"
    },
    "libkey_logo_alt_text": "LibKey Logo",
    "libkey_logo_with_question_mark_alt_text": "LibKey logo with a question mark on top",
    "library_attribution_aria_label": "Access Provided By {libraryName}",
    "library_attribution_header": "Access Provided By",
    "library_attribution_header_all_caps": "ACCESS PROVIDED BY",
    "library_logo_alt_text": "Logo for {libraryName}",
    "linking_options_header": "Linking Options:",
    "locating_article_message": "Locating Article...",
    "more_authentication_info": "More Authentication Info",
    "no_full_text_message": "Your library does not have the full text of:",
    "ok": "OK",
    "permissions_error_message": "You do not have permission to use that function. If this is unexpected, please contact <a href=\"mailto:support@thirdiron.com\">support@thirdiron.com</a> for assistance.",
    "problematic_domain_journals_header_text": "This domain is associated with {x} problematic journals including:",
    "problematic_domain_single_journal_header_text": "This domain is associated with {journal}",
    "problematic_domain_single_journal_violations_header_text": "{journal} has violations including:",
    "problematic_domain_violations_header_text": "Journals associated with this domain have violations including:",
    "problematic_domain_watermark_alt": "Problematic Domain",
    "problematic_journal_cabells_link_text": "View Cabells Report",
    "problematic_journal_cabells_report_header": "Journal Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000637762\">Learn More</a>):",
    "problematic_journal_violation_details_header": "Violation Details from Cabells",
    "problematic_journal_watermark_alt": "Problematic Journal",
    "retraction_details_header": "Retraction Details (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000569841\">Learn More</a>):",
    "retraction_image_alt": "Retracted",
    "retraction_multiple_related_article_link_text": "Retraction Watch related article {linkNumber}",
    "retraction_notice_link_text": "OFFICIAL RETRACTION NOTICE",
    "retraction_reasons_header": "Reason for retraction from Retraction Watch:",
    "retraction_single_related_article_link_text": "Retraction Watch related article",
    "retrieval_assistance_message": "Please see your library for assistance in retrieving this article.",
    "select_library_prompt": "Choose your organization to get started:",
    "splash_panel_help_link_aria_label": "Help Link",
    "splash_panel_help_link_title": "LibKey Support and FAQ",
    "try_again_button": "Try Again",
    "unaffiliated_icon_alt_text": "Unaffiliated Icon",
    "unpaywall_notice": "Note! Article links from Unpaywall are automatically harvested and subject to change over time. If the above link(s) do not work correctly, click on the below link for options available at your library to obtain the article.",
    "visit_third_iron_support_message": "Visit Third Iron Support",
    "vpn_error_message": "Error connecting to account {libraryName} ({libraryId}) from {ipAddress}",
    "vpn_link_message": "If you need assistance in setting up your VPN, please see <a href=\"{url}\" target=\"_blank\">your institution's VPN login information.</a>",
    "wayfless_lookup_no_id_error_message_1": "No DOI or PMID was provided to the search.",
    "wayfless_lookup_no_id_error_message_2": "Please try again.",
    "your_ip_address": "Your IP Address:"
  }], ["fr-fr", {
    "affiliated_cant_find_article_for_doi_message": "Ce DOI n'est pas reconnu, soit parce qu'il est incorrect, soit parce qu'il ne fait pas partie du système LibKey.",
    "affiliated_cant_find_article_for_pmid_message": "Ce PMID n'est pas reconnu, soit parce qu'il est incorrect, soit parce qu'il ne fait pas partie du système LibKey.",
    "article_not_available_message": "Votre bibliothèque n'a pas accès à cet article",
    "article_not_found_error_message": "LibKey ne trouve pas l'article que vous avez demandé. Veuillez vérifier votre lien et réessayer.",
    "articles_in_press": "Articles sous presse",
    "authentication_failed_message": "Échec de l'authentification",
    "cabells_domain_details_summary_header_text": "Summary of Details from Cabells",
    "cabells_domain_link_header_text": "Detailed Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000637762\">Learn More</a>):",
    "cabells_domain_link_text": "View Cabells Report",
    "cancel": "Annuler",
    "cant_find_article_have_unpaywall_message": "Ce DOI n'est pas reconnu, soit parce qu'il est incorrect, soit parce qu'il ne fait pas partie du système LibKey.",
    "cant_locate_full_text_message": "Nous n'avons pas été en mesure de repérer le texte intégral de l'article à votre bibliothèque.",
    "cant_recognize_id_type_message": "Nous n'avons pas pu reconnaître l'identifiant saisi comme DOI ou PMID. Il peut y avoir une faute de frappe dans l'identifiant saisi.",
    "choose_a_language": "Choisissez une langue",
    "controls": {
      "access_options_link_text": "Voir toutes les options pour obtenir cet article",
      "article_in_context_link_text": "Afficher l'article dans son contexte",
      "article_link_link_text": "Lien vers l'article",
      "assistance_link_text": "LIEN VERS LA PAGE D'ACCUEIL DE LA BIBLIOTHÈQUE.",
      "change_library_link_text": "Ce n'est pas votre institution?",
      "default_fallback_template_label": "Options offertes par votre bibliothèque",
      "default_fallback_url_label": "Site Web de la bibliothèque",
      "download_pdf_link_text": "Télécharger le PDF",
      "find_another_article_link_text": "Trouver un autre article",
      "ill_button_text": "DEMANDER CET ARTICLE ET / OU CHERCHER D'AUTRES SOURCES",
      "link_resolver_link_text": "Options offertes par votre bibliothèque",
      "remember_format_choice_aria_label": "Veuillez appuyer sur la barre d'espacement pour mémoriser ce choix de format pendant 24 heures",
      "remember_format_choice_label_text": "Mémoriser ce choix pour 24 heures",
      "search_button_default_text": "Trouver l'article",
      "search_button_doi_text": "Trouver le DOI",
      "search_button_error_text": "Erreur de saisie",
      "search_button_pmid_text": "Trouver le PMID",
      "select_library_button_text": "Confirmer",
      "select_library_placeholder": "Cliquer ici pour parcourir ou rechercher par nom d'institution",
      "select_unaffiliated_link_text": "Institution non répertoriée / non affiliée",
      "unpaywall_article_manuscript_pdf_link_text": "Manuscrit PDF (Libre accès via Unpaywall)",
      "unpaywall_article_manuscript_web_link_text": "Lien vers le manuscrit (Libre accès via Unpaywall)",
      "unpaywall_article_pdf_link_text": "Article PDF (Libre accès via Unpaywall)",
      "unpaywall_article_web_link_text": "Lien vers l'article (Libre accès via Unpaywall)"
    },
    "default_error_screen_message_1": "Une erreur s'est produite lors du contact avec la base de données LibKey.",
    "default_error_screen_message_2": "Third Iron a été notifié",
    "discover_more_header": "Autres options :",
    "email_article_request": "Demande d'article par courriel",
    "expression_of_concern_details_header": "Détails de l’expression de préoccupation (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000607312-about-expressions-of-concern\">en savoir plus</a>) :",
    "expression_of_concern_notice_link_text": "AVIS DE DÉCLARATION DE PRÉOCCUPATION",
    "fields": {
      "search_input_aria_label": "Trouver un article par DOI ou PMID",
      "search_input_placeholder": "Trouver un article par DOI ou PMID",
      "search_input_title": "Trouver un article par DOI ou PMID"
    },
    "format_chooser_region_aria_label": "Région d'information sur l'article",
    "found_article_heading": "Article trouvé!",
    "full_text_format_options_header": "Options de format pour le texte intégral :",
    "generic_journal_cover_alt_text": "Image générique d'une page de couverture",
    "ill_teaser_message": "...Mais nous pourrons peut-être l'obtenir pour vous!",
    "ip_out_of_range_error": "Échec de l'authentification. Votre adresse IP <code>{ipAddress}</code> ne se trouve pas dans l'intervalle d'IP autorisées pour l'institution \"{libraryName}\".",
    "journal_cover_alt_text": "Page de couverture de {journalTitle}",
    "libkey": {
      "description": "LibKey.io est un outil de <a href=\"https://thirdiron.com/\" class=\"orange-emphasis\" target=\"_blank\">Third Iron</a> offert par votre bibliothèque.",
      "powered_by": "Propulsé par",
      "tagline": "Un accès instantané à des millions d'articles fournis par votre bibliothèque"
    },
    "libkey_logo_alt_text": "Logo LibKey",
    "libkey_logo_with_question_mark_alt_text": "Logo LibKey avec un point d’interrogation",
    "library_attribution_aria_label": "Accès fourni par {libraryName}",
    "library_attribution_header": "Accès fourni par",
    "library_attribution_header_all_caps": "ACCÈS FOURNI PAR",
    "library_logo_alt_text": "Logo de {libraryName}",
    "linking_options_header": "Options d'accès :",
    "locating_article_message": "Localisation de l'article...",
    "more_authentication_info": "Plus d'informations sur l'authentification",
    "no_full_text_message": "Votre bibliothèque n'a pas accès au texte intégral de:",
    "ok": "OK",
    "permissions_error_message": "Vous n'êtes pas autorisé à utiliser cette fonction. Si cela n'est pas normal, veuillez contacter <a href='mailto:support@thirdiron.com'>support@thirdiron.com</a> pour obtenir de l'aide.",
    "problematic_domain_journals_header_text": "This domain is associated with {x} problematic journals including:",
    "problematic_domain_single_journal_header_text": "This domain is associated with {journal}",
    "problematic_domain_single_journal_violations_header_text": "{journal} has violations including:",
    "problematic_domain_violations_header_text": "Journals associated with this domain have violations including:",
    "problematic_domain_watermark_alt": "Problematic Domain",
    "problematic_journal_cabells_link_text": "View Cabells Report",
    "problematic_journal_cabells_report_header": "Journal Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000637762\">Learn More</a>):",
    "problematic_journal_violation_details_header": "Violation Details from Cabells",
    "problematic_journal_watermark_alt": "Problematic Journal",
    "retraction_details_header": "Détails sur la rétractation (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000569841\">en savoir plus</a>):",
    "retraction_image_alt": "Rétracté",
    "retraction_multiple_related_article_link_text": "Autre article expliquant la rétractation sur Retraction Watch {linkNumber}",
    "retraction_notice_link_text": "Avis officiel de rétractation",
    "retraction_reasons_header": "Justification de la rétractation dans Retraction Watch:",
    "retraction_single_related_article_link_text": "Article expliquant la rétractation sur Retraction Watch",
    "retrieval_assistance_message": "Veuillez demander l'aide de votre bibliothèque pour récupérer cet article.",
    "select_library_prompt": "Choisissez votre institution pour commencer :",
    "splash_panel_help_link_aria_label": "lien d'aide",
    "splash_panel_help_link_title": "Assistance LibKey et FAQ",
    "try_again_button": "Réessayer",
    "unaffiliated_icon_alt_text": "Icône - Non affilié",
    "unpaywall_notice": "Remarque! Les liens d'articles d'Unpaywall sont automatiquement récoltés et sont sujets à changement au fil du temps. Si le(s) lien(s) ci-dessus ne fonctionne(nt) pas correctement, cliquez sur le lien ci-dessous pour connaître les options disponibles dans votre bibliothèque afin d'obtenir l'article.",
    "visit_third_iron_support_message": "Support technique Third Iron",
    "vpn_error_message": "Erreur de connexion au compte {libraryName} ({libraryId}) depuis {ipAddress}",
    "vpn_link_message": "Si vous avez besoin d'aide pour configurer votre VPN, veuillez consulter <a href=\"{url}\" target=\"_blank\">les informations de connexion VPN de votre institution.</a>",
    "wayfless_lookup_no_id_error_message_1": "Aucun DOI ou PMID n'a été fourni pour la recherche",
    "wayfless_lookup_no_id_error_message_2": "Veuillez réessayer",
    "your_ip_address": "Votre adresse IP:"
  }], ["ja", {
    "affiliated_cant_find_article_for_doi_message": "このDOIは不正、または、LibKeyに収載されていないため認識できません。",
    "affiliated_cant_find_article_for_pmid_message": "このPMIDは不正、または、LibKeyに収載されていないため認識できません。",
    "article_not_available_message": "所属機関からこの文献にアクセスできません：",
    "article_not_found_error_message": "LibKeyはリクエストされた文献を見つけられませんでした。リンクを確認し再度実施してください。",
    "articles_in_press": "未出版論文",
    "authentication_failed_message": "認証失敗",
    "cabells_domain_details_summary_header_text": "Summary of Details from Cabells",
    "cabells_domain_link_header_text": "Detailed Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000637762\">Learn More</a>):",
    "cabells_domain_link_text": "View Cabells Report",
    "cancel": "Cancel",
    "cant_find_article_have_unpaywall_message": "このDOIは不正、または、LibKeyに収載されていないため認識できません。",
    "cant_locate_full_text_message": "フルテキスト文献へのアクセスはできません。",
    "cant_recognize_id_type_message": "入力されたDOI・PMIDを認識できません。確認してください。",
    "choose_a_language": "言語を選択",
    "controls": {
      "access_options_link_text": "その他の選択肢（リンクリゾルバメニュー）",
      "article_in_context_link_text": "掲載誌の目次ページ",
      "article_link_link_text": "論文ページにリンク",
      "assistance_link_text": "図書館のホームページへリンク",
      "change_library_link_text": "所属機関が間違っていますか？",
      "default_fallback_template_label": "所属機関による選択肢",
      "default_fallback_url_label": "図書館Webサイト",
      "download_pdf_link_text": "PDFダウンロード",
      "find_another_article_link_text": "ほかの論文を探しますか？",
      "ill_button_text": "この資料をリクエスト、または他のソースを参照",
      "link_resolver_link_text": "所属機関による選択肢",
      "remember_format_choice_aria_label": "スペースキーを押すとフォーマットの選択を24時間維持します。",
      "remember_format_choice_label_text": "24時間形式設定を保存",
      "search_button_default_text": "論文を探す",
      "search_button_doi_text": "DOIを探す",
      "search_button_error_text": "入力が無効です",
      "search_button_pmid_text": "PMIDを探す",
      "select_library_button_text": "確認",
      "select_library_placeholder": "所属機関をリストから選択、または、検索",
      "select_unaffiliated_link_text": "所属機関がリストになし／所属なし",
      "unpaywall_article_manuscript_pdf_link_text": "論文PDFダウンロード（オープンアクセス）",
      "unpaywall_article_manuscript_web_link_text": "論文ページにリンク（オープンアクセス）",
      "unpaywall_article_pdf_link_text": "PDFダウンロード（オープンアクセス）",
      "unpaywall_article_web_link_text": "論文ページリンク（オープンアクセス）"
    },
    "default_error_screen_message_1": "LibKeyデータベースにアクセス中にエラーが発生しました",
    "default_error_screen_message_2": "Third Ironが通知を受け取りました",
    "discover_more_header": "関連文献：",
    "email_article_request": "文献をメールでリクエスト",
    "expression_of_concern_details_header": "懸念の表明の詳細(<a href=\"https://support.thirdiron.com/support/solutions/articles/72000607312-about-expressions-of-concern\">詳細</a>):",
    "expression_of_concern_notice_link_text": "懸念の表明通知",
    "fields": {
      "search_input_aria_label": "DOI、またはPMIDで論文を探す",
      "search_input_placeholder": "DOI、またはPMIDで論文を探す",
      "search_input_title": "DOI、またはPMIDで論文を探す"
    },
    "format_chooser_region_aria_label": "記事情報領域",
    "found_article_heading": "Found It!",
    "full_text_format_options_header": "フルテキスト形式の選択",
    "generic_journal_cover_alt_text": "ジャーナルカバー",
    "ill_teaser_message": "入手できるかもしれません",
    "ip_out_of_range_error": "あなたのIPアドレス {ipAddress} は、{libraryName}による閲覧範囲外です。",
    "journal_cover_alt_text": "{journalTitle}カバー",
    "libkey": {
      "description": "ご所属機関が導入したLibKey.ioは<a href=\"https://thirdiron.com/\" class=\"orange-emphasis\" target=\"_blank\">Third Iron</a>社の製品です。",
      "powered_by": "Powered by",
      "tagline": "論文への機関アクセスを確認します"
    },
    "libkey_logo_alt_text": "LibKey Logo",
    "libkey_logo_with_question_mark_alt_text": "LibKey logo with a question mark on top",
    "library_attribution_aria_label": "機関購読による閲覧 {libraryName}",
    "library_attribution_header": "機関購読による閲覧",
    "library_attribution_header_all_caps": "機関購読による閲覧",
    "library_logo_alt_text": "{libraryName}ロゴ",
    "linking_options_header": "閲覧オプション",
    "locating_article_message": "論文ロード中",
    "more_authentication_info": "ほかの認証情報",
    "no_full_text_message": "所属機関による購読がありません：",
    "ok": "OK",
    "permissions_error_message": "この機能を使う権限がありません。不明な場合は<a href=\"mailto:support@thirdiron.com\">support@thirdiron.com</a>に通知してください。",
    "problematic_domain_journals_header_text": "This domain is associated with {x} problematic journals including:",
    "problematic_domain_single_journal_header_text": "This domain is associated with {journal}",
    "problematic_domain_single_journal_violations_header_text": "{journal} has violations including:",
    "problematic_domain_violations_header_text": "Journals associated with this domain have violations including:",
    "problematic_domain_watermark_alt": "Problematic Domain",
    "problematic_journal_cabells_link_text": "View Cabells Report",
    "problematic_journal_cabells_report_header": "Journal Report from Cabells (<a href=\"https://support.thirdiron.com/support/solutions/articles/72000637762\">Learn More</a>):",
    "problematic_journal_violation_details_header": "Violation Details from Cabells",
    "problematic_journal_watermark_alt": "Problematic Journal",
    "retraction_details_header": "撤回情報（<a href=\"https://support.thirdiron.com/support/solutions/articles/72000569841\">詳細</a>）：",
    "retraction_image_alt": "撤回論文",
    "retraction_multiple_related_article_link_text": "Retraction Watch関連文献{linkNumber}",
    "retraction_notice_link_text": "撤回の公式通知",
    "retraction_reasons_header": "撤回理由：",
    "retraction_single_related_article_link_text": "Retraction Watch関連文献",
    "retrieval_assistance_message": "この文献の入手のため図書館を参照",
    "select_library_prompt": "ご所属機関を選択",
    "splash_panel_help_link_aria_label": "Needs Translation - \"Help Link\"",
    "splash_panel_help_link_title": "LibKeyサポートとFAQ",
    "try_again_button": "Try Again",
    "unaffiliated_icon_alt_text": "無関係のアイコン",
    "unpaywall_notice": "注）Unpaywall経由のリンクは変更される場合があります。もし、リンクしない場合は下のご所属機関によるオプションをお試しください。",
    "visit_third_iron_support_message": "Third Ironサポートへ",
    "vpn_error_message": "{ipAddress}から{libraryName}のアカウントに接続できません。",
    "vpn_link_message": "VPN接続の手順は機関のVPNログイン情報を参照してください。- Need to know where hyperlink goes",
    "wayfless_lookup_no_id_error_message_1": "DOI、またはPMIDが検索に提供されていません。",
    "wayfless_lookup_no_id_error_message_2": "再度トライしてください。",
    "your_ip_address": "あなたのIPアドレス："
  }]];
});