import { resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import window from 'ember-window-mock';
import Mixin from '@ember/object/mixin';
import localeIsSupported from '../utils/locale-is-supported';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  applicationSession: service(),
  intl: service(),

  async reloadLibrary(library) {
    if (!library) {
      return;
    }
    // To be called after locale is set to pick up localized library attribute values
    await this.store.findRecord('library', library.id, { reload: true });
  },

  async handleLocaleOnLibraryRoute() {
    // Logic to handle localization throughout libkey everywhere except for the
    // library chooser route

    const applicationSession = this.applicationSession;
    const selectedLibrary = applicationSession.selectedLibrary;

    let findLibraryRecord =
      !!selectedLibrary && selectedLibrary !== 'unaffiliated'
        ? this.store.findRecord('library', selectedLibrary)
        : resolve();

    const determineAndSetLocale = async function determineAndSetLocale(
      library
    ) {
      const intl = this.intl;

      const localeOverride = this.applicationSession.localeOverride;
      const {
        supported: localeOverrideSupported,
        supportedLocale: overrideLocaleMatch,
      } = localeIsSupported(localeOverride, library);

      // If we have a localeOverride, but its not supported by the library,
      // clear it
      if (localeOverride && !localeOverrideSupported) {
        this.applicationSession.set('localeOverride', undefined);
      }

      if (localeOverride && localeOverrideSupported) {
        intl.setLocale(overrideLocaleMatch);
        await this.reloadLibrary(library);
      } else {
        // We can't change the navigator.language value in automated tests
        // so first check a property on the window we can set in our test setup
        // so we can simulate having a browser with certain locale settings

        const browserLocale =
          window.testLanguage || navigator.language || navigator.userLanguage;

        const {
          supported: browserLocaleSupported,
          supportedLocale: browserLocaleMatch,
        } = localeIsSupported(browserLocale, library);
        if (browserLocaleSupported) {
          intl.setLocale(browserLocaleMatch);
          await this.reloadLibrary(library);
        } else {
          // No locale is set and we can't match the browser's locale with the library's supported locales
          // Just pick amongst the library's supported languages based on the built in language preference order

          if (localeIsSupported('en-us', library)) {
            intl.setLocale('en-us');
            await this.reloadLibrary(library);
          } else if (localeIsSupported('fr-fr', library)) {
            intl.setLocale('fr-fr');
            await this.reloadLibrary(library);
          } else if (localeIsSupported('ja', library)) {
            intl.setLocale('ja');
            await this.reloadLibrary(library);
          } else if (localeIsSupported('de-de', library)) {
            intl.setLocale('de-de');
            await this.reloadLibrary(library);
          } else {
            // This should never happen but it did.  Either the back end is broken or the library's configuration is broken.
            // Just set the locale to english as a default.
            intl.setLocale('en-us');
            await this.reloadLibrary(library);
          }
        }
      }
    }.bind(this);

    let library = await findLibraryRecord;
    if (!library) {
      // If unaffiliated, locale should be en-us
      this.intl.setLocale(['en-us']);
      return;
    }

    return determineAndSetLocale(library);
  },

  handleLocaleOnLibraryChooserRoute() {
    // the library chooser routes are a little different in that we localize them despite the user
    // being unaffiliated so that we don't get awkward flipping between english and a local language
    const applicationSession = this.applicationSession;
    const intl = this.intl;

    const localeOverride = applicationSession.localeOverride;

    if (localeOverride) {
      intl.setLocale(localeOverride);
      return;
    }

    const browserLocale = window.testLanguage || navigator.language || navigator.userLanguage;
    const browserLanguage = (browserLocale || '').split('-')[0].toLowerCase();

    if (!browserLanguage) {
      intl.setLocale('en-us');
    } else if (browserLanguage === 'en') {
      intl.setLocale('en-us');
    } else if (browserLanguage === 'fr') {
      intl.setLocale('fr-fr');
    } else if (browserLanguage === 'ja') {
      intl.setLocale('ja');
    } else if (browserLanguage === 'de') {
      intl.setLocale('de-de');
    } else {
      intl.setLocale('en-us');
    }
  }
});
