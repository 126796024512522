import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class JournalBox extends Component {
  @service intl;

  get retractedWatermarkImageUrl() {
    const currentLocale = this.intl.primaryLocale;

    if (currentLocale == 'fr-fr') {
      return '/images/retracted-fr.png';
    } else if (currentLocale == 'ja') {
      return '/images/retracted-ja.png';
    } else if (currentLocale == 'de-de') {
      return '/images/retracted-de.png';
     } else {
      return '/images/retracted.png';
    }
  }

  get expressionOfConcernWatermarkImageUrl() {
    const currentLocale = this.intl.primaryLocale;
    if (currentLocale == 'fr-fr') {
      return '/images/expression-of-concern-fr.png';
    } else if (currentLocale == 'ja') {
      return '/images/expression-of-concern-ja.png';
    } else if (currentLocale == 'de-de') {
      return '/images/expression-of-concern-de.png';
    } else {
      return '/images/expression-of-concern.png';
    }
  }

  get problematicJournalWatermarkImageUrl() {
    const currentLocale = this.intl.primaryLocale;
    if (currentLocale == 'fr-fr') {
      return '/images/problematic-journal-fr.png';
    } else if (currentLocale == 'ja') {
      return '/images/problematic-journal-ja.png';
    } else if (currentLocale == 'de-de') {
      return '/images/problematic-journal-de.png';
    } else {
      return '/images/problematic-journal.png';
    }
  }
}
